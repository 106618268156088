import type { ExtendedRouteConfig } from '@/router';

export const authenticationRoutes: ExtendedRouteConfig = {
  path: '/auth',
  meta: {
    layout: 'AppBarLayout'
  },
  children: [
    {
      path: 'login',
      name: 'Login',
      component: () => import('./Login.vue'),
      meta: {
        allowAnonymous: true
      },
    }
  ],
};
