import {
  type NavigationGuardNext,
  type RouteLocation,
  type RouteLocationNormalized,
  type RouteRecordNormalized,
  type RouteRecordRaw,
  RouterView,
  createRouter,
  createWebHistory,
} from 'vue-router'
import { getRefreshToken, routeGuard } from '@descope/vue-sdk'
import type { Roles } from '@common/descope/roles.const'
import type { Permissions } from '@common/descope/permissions.const'
import { nextTick } from 'vue'
import type { Prettify } from '@common/types/prettify.type'
import * as Sentry from '@sentry/vue'
import { eventBus } from './common/event-bus/interruptBus'
import { useSnackbarNotificationStore } from './stores/snackbarNotification.store'
import { useCurrentUserStore } from './stores/currentUserStore'
import { demoRoutes } from './modules/demos/demos.routes'
import { posthogInstance } from './plugins/posthog.plugin'
import { assetIQRoutes } from './modules/assetIQ/assetIQ.routes'
import { marketAnalyzerRoutes } from './modules/marketAnalyzer/marketAnalyzer.routes'
import { developerRoutes } from './modules/developer/developer.routes'
import { requestRoutes } from '@/modules/request/request.routes'
import { financeRoutes } from '@/modules/finance/finance.routes'
import { authenticationRoutes } from '@/modules/authentication/authentication.routes'
import { userRoutes } from '@/modules/user/user.routes'
import { adminRoutes } from '@/modules/admin/admin.routes'
import { dataCleanerRoutes } from '@/modules/dataCleaner/dataCleaner.routes'
import { featureStore } from '@/common/composables/useFeatureFlag'

type LogicalOperator = 'AND' | 'OR'

interface BaseAuthorization {
  type: 'role' | 'permission' | 'tenant:role' | 'tenant:permission'
}

interface RoleAuthorization extends BaseAuthorization {
  type: 'role' | 'tenant:role'
  value: Roles | Roles[]
}

interface PermissionAuthorization extends BaseAuthorization {
  type: 'permission' | 'tenant:permission'
  value: Permissions | Permissions[]
}

interface CompositeAuthorization {
  operator: LogicalOperator
  conditions: AuthorizationExpression[]
}

type AuthorizationExpression = RoleAuthorization | PermissionAuthorization | CompositeAuthorization

type _ExtendedRouteConfig = RouteRecordRaw & {
  meta?: {
    allowAnonymous?: boolean
    authorization?: AuthorizationExpression
    /**
     * @deprecated please use the authorization field instead
     */
    authorizedPermissions?: Permissions[]
    /**
     * Roles that are not associated with tenant
     * @deprecated please use the authorization field instead
     */
    authorizedRoles?: Roles[]
    /**
     * Roles that are associated with a tenant
     * @deprecated please use the authorization field instead
     */
    authorizedTenantRoles?: Roles[]
    /**
     * @deprecated please use the authorization field instead
     */
    authorizedTenantPermissions?: Permissions[]
    featureFlags?: string[]
    breadcrumb?: {
      name: string
      id?: string
      prependIcon?: string
      appendIcon?: string
    }
    // This is a mechanism to protect routes from being accessed by unauthorized users
    // userAccountPermissions?: RouteUserAccountPermissions
    pageName?: string
    layout?: 'AppBarDrawerLayout' | 'public' | 'legal' | 'AppBarLayout'
    layoutModifier?: 'fill-screen' | 'fill-height'
  }
  children?: _ExtendedRouteConfig[]
}
export type ExtendedRouteConfig = Prettify<_ExtendedRouteConfig>

function redirectUserFromHome(to: RouteLocation) {
  const authStore = useCurrentUserStore()
  // If user is not logged in, send them to the login page
  if (!authStore.hasUser)
    return { name: 'Login' }

  // TODO: Admin Role is not well defined! Tenant Admin is better RN
  // if (authStore.hasRootRole('Admin'))
  //   return { name: 'SystemAdmin' }

  if (authStore.hasTenantRole('Staff'))
    return { name: 'ListRequestsDemo' }

  if (authStore.hasTenantRole('Purchase Request Approver'))
    return { name: 'ListFinanceSystemsDemo' }

  if (authStore.hasTenantRole('Staff'))
    return { name: 'ListRequestsDemo' }

  // TODO: Add Logic to determine where the user should go
  if (authStore.hasTenantPermission('Lifecycle Data Manager'))
    // TODO: Modify this page to show a note if a tenant has not been selected.
    return { name: 'Lifecycle Data Uploads' }

  // Send them to a dashboard right away.
  if (authStore.hasTenantRole('Dashboard User'))
    return { name: 'StandardDashboard', params: { type: 'lifecycle' } }

  return { name: 'Home' }
}

const routes = [
  {
    path: '/',
    // components: {
    // nav: () => import('@/components/NavigationBar.vue'),
    // default: RouterView,
    // },
    children: [
      {
        path: 'ROI',
        name: 'ROIDashboard',
        meta: {
          layoutModifier: 'fill-screen',
          allowAnonymous: true,
          layout: 'AppBarLayout'
        },
        components: {
          nav: () => import('@/components/NavigationBar.vue'),
          default: () => import('@/modules/demos/views/RoiDash.vue')
        },
      },
      ...([
        {
          path: '',
          // name: 'Home',
          meta: {
            allowAnonymous: true,
            layout: 'AppBarLayout',
          },
          beforeEnter: (to, _from, next) => {
            return next(redirectUserFromHome(to))
          },
          // Should never be reached
          component: () =>
            import('@/views/Home.vue'),
        },
        authenticationRoutes,
        userRoutes,
        // dataCleanerRoutes,
        demoRoutes,
        requestRoutes,
        financeRoutes,
        assetIQRoutes,
        marketAnalyzerRoutes,
        developerRoutes,
      ] as _ExtendedRouteConfig[]).map((route) => {
        const component = route.component ? route.component : RouterView
        return Object.assign({}, route, {
          component: undefined,
          components: {
            nav: () => import('@/components/NavigationBar.vue'),
            default: component,
          },
        })
      }),
      adminRoutes,
    ],
  },
  // TODO: It would be better to not redirect the user to an unauthorized path but to swap out the component
  {
    path: '/Unauthorized',
    name: 'NotAuthorized',
    meta: {
      layout: 'AppBarLayout',
    },
    components: {
      nav: () => import('@/components/NavigationBar.vue'),
      default: () => import('@/views/NotAuthorized.vue'),
    },
  },
  {
    path: '/Unavailable',
    name: 'Unavailable',
    meta: {
      layout: 'AppBarLayout',
    },
    components: {
      nav: () => import('@/components/NavigationBar.vue'),
      default: () => import('@/views/Unavailable.vue'),
    },
  },
  {
    path: '/Home',
    name: 'Home',
    meta: {
      layout: 'AppBarLayout',
    },
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    meta: {
      layout: 'AppBarLayout',
    },
    components: {
      nav: () => import('@/components/NavigationBar.vue'),
      default: () => import('@/views/NotFound.vue'),
    },
  },
] as ExtendedRouteConfig[]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

function redirectToLogin(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  if (to.name !== 'Login') {
    const location: RouteLocation = { name: 'Login' } as RouteLocation
    if (to.fullPath !== '/')
      location.query = { r: to.fullPath }
    return next(location)
  }
  return next()
}

type ExtendedRouteLocationNormalized = Prettify<Omit<RouteLocationNormalized, 'matched'> & {
  matched: Prettify<RouteRecordNormalized & {
    meta: ExtendedRouteConfig['meta']
  }>[]
  meta: ExtendedRouteConfig['meta']
}>

function isAuthorizedToGoToRoute(to: ExtendedRouteLocationNormalized, authStore: ReturnType<typeof useCurrentUserStore>) {
  // Helper function to evaluate the new authorization expressions recursively
  function evaluateAuthorizationExpression(expression: AuthorizationExpression): boolean {
    if ('operator' in expression) {
      // CompositeAuthorization
      if (expression.operator === 'AND') {
        return expression.conditions.every(evaluateAuthorizationExpression)
      }
      else if (expression.operator === 'OR') {
        return expression.conditions.some(evaluateAuthorizationExpression)
      }
    }
    else {
      // RoleAuthorization or PermissionAuthorization
      switch (expression.type) {
        case 'role':
          return authStore.hasRootRole(expression.value as Roles)
        case 'permission':
          return authStore.hasRootPermission(expression.value as Permissions)
        case 'tenant:role':
          return authStore.hasTenantRole(expression.value as Roles)
        case 'tenant:permission':
          return authStore.hasTenantPermission(expression.value as Permissions)
      }
    }
    return false
  }

  // Evaluate new authorization strategy for each matched route
  const isAuthorizedNewStrategy = to.matched.every((route) => {
    const authorization = route.meta.authorization
    if (authorization)
      return evaluateAuthorizationExpression(authorization)
    return true // If no new authorization is defined, assume authorized
  })

  // Fallback to deprecated authorization checks for each matched route
  const rootRoles = to.matched.filter(route => route.meta?.authorizedRoles).every((route) => {
    const authorizedRoles = route.meta.authorizedRoles as Roles[]
    return authStore.hasRootRole(authorizedRoles)
  })
  const tenantRoles = to.matched.filter(route => route.meta?.authorizedTenantRoles).every((route) => {
    const authorizedRoles = route.meta.authorizedTenantRoles as Roles[]
    return authStore.hasTenantRole(authorizedRoles)
  })
  const rootPermissions = to.matched.filter(route => route.meta?.authorizedPermissions).every((route) => {
    const authorizedPermissions = route.meta.authorizedPermissions as Permissions[]
    return authStore.hasRootPermission(authorizedPermissions)
  })
  const tenantPermissions = to.matched.filter(route => route.meta?.authorizedTenantPermissions).every((route) => {
    const authorizedPermissions = route.meta.authorizedTenantPermissions as Permissions[]
    return authStore.hasTenantPermission(authorizedPermissions)
  })

  return isAuthorizedNewStrategy && rootRoles && tenantRoles && rootPermissions && tenantPermissions
}

router.beforeEach(async (to: ExtendedRouteLocationNormalized, from: ExtendedRouteLocationNormalized, next: NavigationGuardNext) => {
  // Look at all matched routes in `to`, find the last allowAnonymous flag and return its value
  let allowAnonymous = false
  const features = featureStore()
  for (const record of to.matched) {
    if (record.meta && typeof record.meta.allowAnonymous === 'boolean')
      allowAnonymous = record.meta.allowAnonymous
    // Break if allowAnonymous is true
    if (allowAnonymous)
      break
  }

  // Check Feature Flags
  let blockedByFlags = false
  const featureFlags = to.matched.reduce((acc, route) => {
    if (route.meta?.featureFlags)
      return acc.concat(route.meta.featureFlags)

    return acc
  }, [] as string[])
  for (const flag of featureFlags) {
    if (!features.check(flag))
      blockedByFlags = true

    // Break if blocked by flags
    if (blockedByFlags)
      break
  }

  if (blockedByFlags)
    return next({ name: 'NotAuthorized' })

  // Check authentication
  const isAuthenticated = !!getRefreshToken() && await routeGuard() // Undocumented descope function except in the example
  const authStore = useCurrentUserStore()
  if (isAuthenticated && authStore.firstSeen && to.name === 'FirstSeen')
    return next()

  if (authStore.firstSeen)
    return next({ name: 'FirstSeen' })

  if (allowAnonymous) {
    if (isAuthenticated) {
      if (!authStore.hasUser)
        await authStore.whoami()

      if (authStore.firstSeen)
        // TODO: Fix first seen redirect + tenant selection
        return next({ name: 'FirstSeen' })
    }
    return next()
  }
  if (!isAuthenticated)
    return redirectToLogin(to, from, next)

  if (!authStore.hasUser)
    await authStore.whoami()

  // // Check if user has a redirect
  if (to.query.r) {
    // Check if the user is coming from the Login route
    if (from.name === 'Login') {
      const redirectPath = to.query.r as string
      // Attempt to resolve the target route
      const targetRoute = router.resolve(redirectPath)
      // Check if the target route exists and is valid
      if (targetRoute && targetRoute.name) {
        // Optional: Check if the user is authorized to access the target route
        // This step depends on your authorization logic, e.g., checking against user roles
        // For simplicity, this example assumes the user is authorized
        const isAuthorized = isAuthorizedToGoToRoute(targetRoute, authStore) // Replace with your actual authorization check
        if (isAuthorized) {
          // Redirect to the specified route
          return next({ path: redirectPath })
        }
      }
      // If the redirect is not valid or the user is not authorized, redirect to the home page
      next({ path: '/' })
      Sentry.captureEvent({
        message: 'User was not authorized to access the redirection route',
        extra: {
          to: to.fullPath,
          from: from.fullPath,
          redirectPath,
          targetRoute: targetRoute.fullPath,
        },
      })
      return
    }
    else {
      // If not coming from the Login route, remove the `r` query parameter
      const { r, ...rest } = to.query
      return next({ ...to, query: rest })
    }
  }

  // TODO: This is using a deprecated authorization strategy. We should use the new one.
  // Check user authorization
  if (to.meta.authorizedRoles) {
    const isAuthorized = isAuthorizedToGoToRoute(to, authStore)
    if (!isAuthorized)
      return next({ name: 'NotAuthorized' })

    return next()
  }
  else {
    return next()
  }
})

router.afterEach((to, from, failure) => {
  if (!failure) {
    nextTick(() => {
      posthogInstance?.capture('$pageview', {
        path: to.fullPath,
        from: from.fullPath,
      })
    })
  }
})

// When a Deauthentication event is received, redirect to login
eventBus.on('authFailure', () => {
  router.push({ name: 'Login' })
})

// When the user is First Seen, redirect to First Seen
eventBus.on('firstSeen', () => {
  if (router.currentRoute.value.name !== 'FirstSeen')
    router.push({ name: 'FirstSeen' })
})

// When session expires, redirect to login
eventBus.on('sessionExpired', () => {
  router.push({ name: 'Login' })
  useSnackbarNotificationStore().notify('Your session has expired. Please log in again.')
})

eventBus.on('databaseDisconnected', () => {
  router.push({ name: 'Unavailable' })
  useSnackbarNotificationStore().notify('The database is currently unavailable. Please try again later.')
})

export default router
