import { RouterView } from 'vue-router'
import { demoOrganizationsRoutes } from './modules/demoOrganizations/demoOrganizations.routes'
import type { ExtendedRouteConfig } from '@/router'
import AdminNavigation from '@/modules/admin/components/AdminNavigation.vue'

export const adminRoutes: ExtendedRouteConfig = {
  path: 'admin',
  components: {
    drawer: AdminNavigation,
    nav: () => import('@/components/NavigationBar.vue'),
    default: RouterView,
  },
  meta: {
    layout: 'AppBarDrawerLayout',
    authorizedRoles: ['Admin'],
  },
  children: [
    {
      path: '',
      name: 'SystemAdmin',
      redirect: { name: 'AdminCustomers' },
    },
    {
      path: 'Dashboard',
      name: 'AdminDashboard',
      component: () => import('@/modules/admin/views/Dashboard.vue'),
    },
    {
      path: 'Customers',
      meta: {
        authorizedRoles: ['Admin'],
      },
      children: [
        {
          path: '',
          name: 'AdminCustomers',
          component: () => import('@/modules/admin/views/Customers.vue'),
        },
        {
          path: ':customerId',
          component: () => import('@/modules/admin/views/Customer.vue'),
          props: true,
          children: [
            {
              path: '',
              alias: 'Users',
              meta: {
                authorizedRoles: ['Admin'],
              },
              component: () => import('@/modules/admin/views/CustomerUsers.vue'),
              props: true,
              children: [
                {
                  path: '',
                  props: true,
                  name: 'AdminCustomerUsers',
                  component: () => import('@/modules/admin/components/CustomerUserList.vue')
                },
                {
                  path: 'Invitations',
                  props: true,
                  name: 'AdminCustomerUserInvites',
                  component: () => import('@/modules/admin/components/CustomerUserInvitationsList.vue')
                },
              ],
            },
            {
              path: 'Dashboards',
              name: 'AdminCustomerDashboards',
              component: () => import('@/modules/admin/modules/dashboards/views/CustomerDashboards.vue'),
              props: true,
            },
            {
              path: 'Entitlements',
              name: 'AdminCustomerEntitlements',
              component: () => import('@/modules/admin/modules/entitlements/views/CustomerEntitlements.vue'),
              props: true,
            },
            {
              path: 'Descope',
              name: 'AdminCustomerDescope',
              component: () => import('@/modules/admin/views/CustomerDescope.vue'),
              props: true,
            },
            {
              path: 'AcquisitionPlanner',
              name: 'AdminCustomerAcquisitionPlanner',
              component: () => import('@/modules/admin/views/CustomerAcquisitionPlanner.vue'),
              props: true,
            },
            {
              path: 'Uploads',
              name: 'AdminCustomerUploads',
              component: () => import('@/modules/admin/modules/uploads/views/CustomerUploads.vue'),
              props: true,
            },
          ],
        },
      ],
    },
    {
      path: 'Users',
      meta: {
        authorizedPermissions: ['Admin User List'],
      },
      children: [
        {
          path: '',
          name: 'AdminUsers',
          component: () => import('@/modules/admin/views/Users.vue'),
        },
        {
          path: ':userId',
          name: 'AdminUser',
          component: () => import('@/modules/admin/views/User.vue'),
          props: true,
        },
      ],
    },
    {
      path: 'Leads',
      name: 'AdminLeads',
      component: () => import('@/modules/admin/views/Leads.vue'),
    },
    demoOrganizationsRoutes
  ],
}
