import type { ExtendedRouteConfig } from '@/router'

export const requestRoutes: ExtendedRouteConfig = {
  path: '/requests',
  meta: {
    layout: 'AppBarLayout',
    authorization: {
      operator: 'OR',
      conditions: [
        {
          type: 'tenant:role',
          value: ['Staff', 'Tenant Admin', 'Admin']
        },
        {
          type: 'role',
          value: ['Staff', 'Tenant Admin', 'Admin']
        }
      ]
    },
    featureFlags: ['purchase_requests_demo']
  },
  children: [
    {
      path: '',
      name: 'ListRequestsDemo',
      component: () => import('./views/ListRequestsDemo.vue')
    },
    {
      path: 'new',
      name: 'NewDraftRequestDemo',
      component: () => import('./views/DraftRequestDemo.vue')
    },
    {
      path: 'draft/:id',
      name: 'EditDraftRequestDemo',
      component: () => import('./views/DraftRequestDemo.vue'),
      props: true
    },
    {
      path: ':id',
      name: 'ViewRequestDemo',
      component: () => import('./views/ManageRequestDemo.vue'),
      props: true
    }
  ]
}
