import type { ExtendedRouteConfig } from '@/router'

export const financeRoutes: ExtendedRouteConfig = {
  path: '/finance',
  meta: {
    layout: 'AppBarLayout',
    authorization: {
      operator: 'OR',
      conditions: [
        {
          type: 'tenant:role',
          value: ['Purchase Request Approver', 'Tenant Admin', 'Admin']
        },
        {
          type: 'role',
          value: ['Purchase Request Approver', 'Tenant Admin', 'Admin']
        }
      ]
    },
    featureFlags: ['purchase_requests_demo']
  },
  children: [
    // TODO: Retain organization selection if set, otherwise default to all
    {
      path: '',
      name: 'ListFinanceSystemsDemo',
      component: () => import('./views/ListFinanceSystemsDemo2.vue'),
    },
    {
      path: ':modality/:priority/:requestType/requests',
      name: 'ListFinanceRequestsDemo',
      component: () => import('./views/ListFinanceRequestsDemo2.vue'),
      props: true
    },
    {
      path: ':modality/:priority/:requestType/:requestId',
      name: 'ViewFinanceRequestDemo',
      component: () => import('./views/ViewFinanceRequestDemo.vue'),
      props: true
    }
  ]
}
