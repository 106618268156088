/**
 * @deprecated Please use '@common/datatable/datatable.class'
 */
import { type Static, t } from 'elysia'
import { type AnyColumn, type TableRelationalConfig, asc, desc } from 'drizzle-orm'

export const itemsPerPageOptions: Array<{ title: string, value: number }> = [
  // The first one is the default and is enforced in the defaults section in vuetify.plugin.ts
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: 100, title: '100' },
  { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' }
]

export function PaginationWithSortSchemaBuilder<T extends Array<string | number> | undefined>(fields?: T) {
  const keys = t.Union((fields || []).map(f => t.Literal(f as Exclude<T, undefined>[number])))
  const anyKey = t.String({ description: 'Sort By Key' })

  type SortByKeyType = T extends Array<string | number> ? typeof keys : typeof anyKey

  return t.Partial(
    t.Object({
      sortBy: t.Union([
        t.Array(
          // Vuetify
          t.Object({
            key: (fields ? keys : anyKey) as SortByKeyType,
            order: t.Union([t.Literal('asc'), t.Literal('desc'), t.Literal('1', { description: 'asc' }), t.Literal('-1', { description: 'desc' })])
          })
        ),
        t.Array(
          // PrimeVue
          t.Object({
            field: (fields ? keys : anyKey) as SortByKeyType,
            order: t.Union([t.Literal('asc'), t.Literal('desc'), t.Literal('1', { description: 'asc' }), t.Literal('-1', { description: 'desc' })])
          })
        )
      ]),
      size: t.Numeric({ description: 'Page Size' }),
      page: t.Numeric({ description: 'Page Number' })
    })
  )
}

export type PaginationParams = Static<ReturnType<typeof PaginationWithSortSchemaBuilder>>
type ResolvedPagination = Required<PaginationParams>

export function resolvePaginationParams(params?: PaginationParams, defaultSort?: Array<{ key: string, order: 'asc' | 'desc' }>): ResolvedPagination {
  const sortBy = params?.sortBy?.length
    ? params.sortBy
    : defaultSort ?? []
  const size = params?.size ?? itemsPerPageOptions[0].value
  const page = params?.page ?? 1

  return {
    sortBy,
    size,
    page
  }
}

export const orderMethodMap = {
  asc,
  desc,
  // PrimeVue's data table expresses ordering using numbers
  '-1': desc,
  '1': asc
} as const

export function sortByBuilder<TTableConfig extends TableRelationalConfig = TableRelationalConfig>(table: TTableConfig['columns'], sortBy: ResolvedPagination['sortBy']) {
  return sortBy.map(val => 'field' in val
    ? orderMethodMap[val.order](table[val.field])
    : orderMethodMap[val.order](table[val.key]))
}
