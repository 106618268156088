import type { ExtendedRouteConfig } from '@/router';

export const financeRoutes: ExtendedRouteConfig = {
  path: '/finance',
  meta: {
    layout: 'AppBarLayout',
    authorizedRoles: ['Tenant Admin', 'Admin'],
    featureFlags: ['purchase_requests']
  },
  children: [
    {
      path: '',
      name: 'ListFinanceSystems',
      component: () => import('./views/ListFinanceSystems.vue'),
    },
    {
      path: ':requestModalityId/:priority/requests',
      name: 'ListFinanceRequests',
      component: () => import('./views/ListFinanceRequests.vue'),
      props: true
    },
    {
      path: ':requestModalityId/:priority/:requestId',
      name: 'ViewFinanceRequest',
      component: () => import('./views/ViewFinanceRequest.vue'),
      props: true
    }
  ]
};
