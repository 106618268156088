<script lang="ts" setup>
import { useSession } from '@descope/vue-sdk'
import LayoutRouter from './layouts/LayoutRouter.vue'
import { useCurrentUserStore } from '@/stores/currentUserStore'

const { isAuthenticated } = useSession()
const currentUserStore = useCurrentUserStore()
const router = useRouter()

watch(isAuthenticated, async (newVal, oldVal) => {
  if (!newVal && oldVal) {
    currentUserStore.clearUser()
    router.push('/')
  }
}, { immediate: true })
</script>

<template>
  <LayoutRouter>
    <RouterView />
  </LayoutRouter>
</template>
