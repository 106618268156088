import { ref, onMounted, onUnmounted, watch } from 'vue';
import { unrefElement } from '@vueuse/core'

// This type now explicitly allows for any element type, which should cover most use cases
// including components that render a root element.
export function useComponentSize(targetRef: Ref) {
  const width = ref<number>(0);
  const height = ref<number>(0);

  const updateSize = (entries: ResizeObserverEntry[]) => {
    if (entries.length === 0) return;
    const entry = entries[0];
    const borderLeftWidth = parseFloat(getComputedStyle(entry.target).borderLeftWidth);
    const borderRightWidth = parseFloat(getComputedStyle(entry.target).borderRightWidth);
    const borderTopWidth = parseFloat(getComputedStyle(entry.target).borderTopWidth);
    const borderBottomWidth = parseFloat(getComputedStyle(entry.target).borderBottomWidth);

    const paddingLeft = parseFloat(getComputedStyle(entry.target).paddingLeft);
    const paddingRight = parseFloat(getComputedStyle(entry.target).paddingRight);
    const paddingTop = parseFloat(getComputedStyle(entry.target).paddingTop);
    const paddingBottom = parseFloat(getComputedStyle(entry.target).paddingBottom);

    width.value = entry.contentRect.width + paddingLeft + paddingRight + borderLeftWidth + borderRightWidth;
    height.value = entry.contentRect.height + paddingTop + paddingBottom + borderTopWidth + borderBottomWidth;
  };

  const observer = new ResizeObserver(updateSize);

  onMounted(() => {
    watch(targetRef, (newValue, oldValue) => {
      if (newValue) {
        observer.observe(unrefElement(newValue));
      } else if (oldValue) {
        observer.unobserve(unrefElement(oldValue));
      }
    }, { immediate: true });
  });

  onUnmounted(() => {
    if (targetRef.value) {
      observer.unobserve(unrefElement(targetRef.value));
    }
  });

  return { width, height };
}
