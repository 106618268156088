/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */
import type { App } from 'vue'
import { getSdk } from '@descope/vue-sdk'
import { VueQueryPlugin } from '@tanstack/vue-query'
import type { VueQueryPluginOptions } from '@tanstack/vue-query'
import vuetify from './vuetify.plugin'
import { setupI18n } from './i18n.plugin'
import { setupSentry } from './sentry.plugin'
import { setupMotion } from './motion.plugin'
import { portalVue } from './portal.plugin'
import { descope } from './descope.plugin'
import { setupPosthog } from './posthog.plugin'
import router from '@/router'
import pinia from '@/stores'
import { useCurrentUserStore } from '@/stores/currentUserStore'

const vueQueryOptions: VueQueryPluginOptions = {
  enableDevtoolsV6Plugin: true,
  // queryClientConfig: {
  //   defaultOptions: {
  //     queries: {
  //       gcTime: 1000 * 60 * 60 * 24,
  //       // staleTime: 1000 * 10,
  //     },
  //   },
  // },
}

export function registerPlugins(app: App) {
  app.use(descope)
  app.use(vuetify)
  app.use(pinia) // pinia should be created early on, before router because router uses it
  app.use(router)
  app.use(portalVue)
  app.use(VueQueryPlugin, vueQueryOptions)
  setupI18n(app)
  setupPosthog(app)
  setupSentry(app)
  setupMotion(app)

  // Setup Listeners
  app.use({
    install() {
      const currentUserStore = useCurrentUserStore()
      getSdk().onSessionTokenChange((newToken) => {
        currentUserStore.updateSessionToken(newToken)
      })
    },
  })
}
