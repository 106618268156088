// authStore.ts
import { defineStore } from 'pinia'
import type { Roles } from '@common/descope/roles.const'
import type { Permissions } from '@common/descope/permissions.const'
import { getJwtPermissions, getJwtRoles, getRefreshToken, getSdk, getSessionToken } from '@descope/vue-sdk'
import { jwtDecode } from 'jwt-decode'
import { hasIntersection } from '@common/utils/hasIntersection'
import { isArray, isObject } from '@sindresorhus/is'
import type { Prettify } from '@common/types/prettify.type'
import * as Sentry from '@sentry/vue'
import { useTreaty } from '@/common/composables/useTreaty'
import type { User } from '@/common/types/user.types'
import { posthogInstance } from '@/plugins/posthog.plugin'

const app = useTreaty()

export const useCurrentUserStore = defineStore('currentUser', {
  state: () => ({
    user: null as User | null,
    firstSeen: false,
    sessionToken: getSessionToken(),
  }),

  getters: {
    hasUser(): boolean {
      return this.user !== null
    },
    getUser(): User | null {
      return this.user
    },
    getOrganizationDemoIds(state) {
      if (state.user)
        return state.user.organizationsDemo

      return []
    },
    getUserOrganizations(state) {
      if (state.user)
        return state.user.organizations

      return []
    },
    getSelectedOrganization(state): Prettify<Exclude<User, null>['organizations'][0]> | null {
      if (state.user == null || !this.sessionToken)
        return null
      // Get selected tenant
      const decodedToken = jwtDecode(this.sessionToken)
      if (!('dct' in decodedToken))
        return null
      // https://docs.descope.com/knowledgebase/descopeflows/tenantselectcomponent/#jwt-after-tenant-selection
      const selectedTenant = decodedToken.dct
      // Find the organization that is the selected tenant
      return state.user.organizations.find(org => org.descopeTenantId === selectedTenant) ?? null
    },
    hasRootRole(state) {
      return (authorizedRoles: Roles[] | Roles): boolean => {
        const token = this.sessionToken
        if (!token || state.user == null)
          return false
        // console.log(descopeSdk)
        if (isArray(authorizedRoles))
          return hasIntersection(getJwtRoles(token), authorizedRoles)

        return hasIntersection(getJwtRoles(token), [authorizedRoles])
      }
    },
    hasRootPermission(state) {
      return (permissions: Permissions | Permissions[]): boolean => {
        const token = this.sessionToken
        if (!token || state.user == null)
          return false
        if (isArray(permissions))
          return hasIntersection(getJwtPermissions(token), permissions)

        return getJwtPermissions(token).includes(permissions)
      }
    },
    hasTenantRole(state) {
      return (authorizedRoles: Roles[] | Roles): boolean => {
        const token = state.sessionToken
        if (!token || state.user == null || !this.getSelectedOrganization)
          return false
        if (isArray(authorizedRoles))
          return hasIntersection(getJwtRoles(token, this.getSelectedOrganization.descopeTenantId), authorizedRoles)

        return hasIntersection(getJwtRoles(token, this.getSelectedOrganization.descopeTenantId), [authorizedRoles])
      }
    },
    hasTenantPermission(state) {
      return (permissions: Permissions | Permissions[]): boolean => {
        const token = this.sessionToken
        if (!token || state.user == null || !this.getSelectedOrganization)
          return false
        if (isArray(permissions))
          return hasIntersection(getJwtPermissions(token, this.getSelectedOrganization.descopeTenantId), permissions)

        return getJwtPermissions(token, this.getSelectedOrganization.descopeTenantId).includes(permissions)
      }
    },
    hasNecessaryRole(state) {
      return (roles: { rootRoles: Roles[] | Roles, tenantRoles: Roles[] | Roles } | Roles[] | Roles): boolean => {
        if (state.user == null)
          return false
        if (isObject(roles) && !isArray(roles))
          return this.hasRootRole(roles.rootRoles) || this.hasTenantRole(roles.tenantRoles)

        return this.hasRootRole(roles) || this.hasTenantRole(roles)
      }
    },
    hasNecessaryPermission(state) {
      return (permission: Permissions): boolean => {
        if (state.user == null)
          return false
        return this.hasRootPermission(permission) || this.hasTenantPermission(permission)
      }
    }
  },
  actions: {
    async whoami() {
      const { data } = await app.whoami.get()
      this.setUser(data)
    },
    setUser(user: User | null) {
      if (user) {
        // TODO: Make sure posthog id set up correctly for Feature Flags!
        // Is this the right place to put it?
        posthogInstance?.identify(String(user.id), {
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
        })
        window.Marker?.setReporter({
          email: user.email,
          fullName: `${user.firstName} ${user.lastName}`,
        })
        Sentry.setTag('user_id', user.id)
        Sentry.setUser({
          id: user.id,
          email: user.email,
          fullName: `${user.firstName} ${user.lastName}`,
        })
      }
      else {
        posthogInstance?.reset()
        posthogInstance?.reloadFeatureFlags()
        window.Marker?.setReporter({
          email: 'anonymous@assetiq.io',
          fullName: '',
        })
        Sentry.setTag('user_id', 'anonymous')
        Sentry.setUser(null)
      }
      this.user = user
    },
    clearUser() {
      this.user = null
      this.firstSeen = false
      Sentry.setTag('user_id', 'anonymous')
      window.Marker?.setReporter({
        email: 'anonymous@assetiq.io',
        fullName: '',
      })
      posthogInstance?.reset()
      posthogInstance?.reloadFeatureFlags()
      Sentry.setUser(null)
    },
    setFirstSeen(val: boolean) {
      this.firstSeen = val
    },
    async setTenant(descopeTenantId: string) {
      // Make sure that they are selecting a valid tenant
      const sdk = getSdk()
      // sdk.onSessionTokenChange(() => {
      //   const sessionToken = getSessionToken()
      //   const tenants = sdk.getTenants(sessionToken)
      // })
      const sessionToken = getSessionToken()
      const tenants = sdk.getTenants(sessionToken)
      if (!tenants.includes(descopeTenantId))
        throw new Error('Invalid tenant')

      const refreshToken = getRefreshToken()
      // Once this runs, what should we do?
      await sdk.selectTenant(descopeTenantId, refreshToken)
      await this.whoami()
    },
    updateSessionToken(newToken: string) {
      this.sessionToken = newToken
    },
    getTenantIds() {
      // Make sure that they are selecting a valid tenant
      const sdk = getSdk()

      const sessionToken = getSessionToken()
      if (!sessionToken)
        return []
      return sdk.getTenants(sessionToken)
    }
  },
})
