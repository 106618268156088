/**
 * purify
 * @author Matthew Ary <matthew.ary@acquisitionsimplicity.com> <matthewj.c.ary@gmail.com>
 * Created on 26-01-2023.
 */
import DOMPurify from 'dompurify'

export function purify(input: string): string {
  return DOMPurify.sanitize(input)
}
